// User Pages

.app-logo {}

.app-logo-inverse {
  color: white;
  text-align: center;
  font-size: 30px;
  text-transform: uppercase;
}

.app-logo-color {}

.app-login-box {
  .app-logo {
    margin-bottom: $layout-spacer-lg;
  }

  h4 {
    margin-bottom: $layout-spacer-x;
    font-weight: normal;

    div {
      opacity: .6;
    }

    span {
      font-size: $font-size-lg;
    }
  }
}